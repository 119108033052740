// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "s_sL d_gS d_cw d_dv";
export var quoteParagraphCenter = "s_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "s_gV d_gV d_cw d_dx";
export var quoteRowLeft = "s_sM d_bG";
export var quoteRowCenter = "s_sN d_bD";
export var quoteRowRight = "s_sP d_bH";
export var quoteWrapper = "s_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "s_gR d_gR";
export var quoteExceptionSmall = "s_rW q_rW";
export var quoteExceptionNormal = "s_rX q_rX";
export var quoteExceptionLarge = "s_rY q_rY";
export var quoteAuthorExceptionSmall = "s_rZ q_rZ";
export var quoteAuthorExceptionNormal = "s_r0 q_r0";
export var quoteAuthorExceptionLarge = "s_r1 q_r1";